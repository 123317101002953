import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import FacebookIcon from "mdi-react/FacebookBoxIcon";
// import LinkedinIcon from "mdi-react/LinkedinBoxIcon";
import Container from '@material-ui/core/Container';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from '@material-ui/core/Checkbox';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { postRequestWithOutToken } from '../../helper/helper';
import detectBrowserLanguage from '../../helper/browserLanguage'
import ReactFacebookLoginWithButton from './facebookBtn';
import "./signUp.css";

const styles = ({
    Logo: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0 10px 0',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        boxShadow: '0 3px 5px #aaa',
    },
    headerH2: {
        fontSize: '1.7em',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    },
    italic: {
        color: '#cacaca',
        fontStyle: 'italic',
        fontWeight: '300',
        fontSize: '1.7em',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    },
    headerLogo: {
        display: 'flex',
        alignItems: 'center',
    },
    signUp: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '50px 0',
    },
    leftIcon: {
        marginRight: '10px',
        width: '35px',
        height: '35px',
        color: 'white',
    },
    socialDiv:
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px',
        marginBottom: '10px',
        borderBottom: '1px solid #eee',
    },
    SignUpFormDiv:
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    SignUpFormDiv1:
    {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px auto',
    },
    bgColor: {
        backgroundColor: 'red',
        color: 'white'
    }
});


class SignUp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            communications: [
                "Text Message",
                "Email",
                "Phone"
            ],
            email: '',
            fullname: '',
            firstname: '',
            lastname: '',
            password: '',
            confirmPassword: '',
            phone: '',
            preferred_communication: '',
            language: '',
            openSnackBar: false,
            signUpAlert: '',
            subscription_flag: false,
            tnc_flag: false,
        };
        this.source = this.props.location && this.props.location.state && this.props.location.state.source;
        this.paramLanguage = this.props.location && this.props.location.state && this.props.location.state.lang;
    };


    goToLogin = () => {
        this.props.history.push('/login', { lang: this.state.language })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmissionChange = () => {
        this.setState((prevState) => ({
            subscription_flag: !prevState.subscription_flag
        }));
    }

    handleTNCChange = () => {
        this.setState((prevState) => ({
            tnc_flag: !prevState.tnc_flag
        }));
    }

    handlePhoneChange = (value) => {
        this.setState({
            phone: value
        })
    }

    handleCloseSnackbar = () => {
        this.setState({
            openSnackBar: false
        })
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        let lang = urlParams.get('lang') || urlParams.get('Lang');
        let renderLang = "en"

        if (!lang) {
            if (this.paramLanguage) {
                if (this.paramLanguage.includes("es")) {
                    renderLang = "es"
                }
            } else {
                lang = detectBrowserLanguage();
                if (lang.includes("es")) {
                    renderLang = "es"
                }
            }
        } else {
            if (lang.includes("es")) {
                renderLang = "es"
            }
        }
        this.setState({
            language: renderLang
        })
    }

    responseFacebook = (response) => {
        if (response.email) {
            this.setState({
                email: response.email
            })
        }
        if (response.name) {
            this.setState({ fullname: response.name })
        }
    }

    // responseLinkedin = response => {
    //     console.log(response)
    // }

    render() {
        const { classes } = this.props;
        const { language, openSnackBar, signUpAlert } = this.state;

        return (
            <div className="signUpContainer">
                <div className="SignUp-Form">
                    <Container maxWidth="md" style={{ margin: 'auto' }}>

                        <div className="SignUpForm-new-width dFlex" style={{ marginTop: 20, marginBottom: 50 }}>
                            <div className="left_side">
                                <img src="/image/lonestar-white.png" alt="img" style={{ width: '60%' }} />
                            </div>
                            <div style={{ paddingTop: '25px' }}>
                                <div className="SignUpForm-title-div" >
                                    <div className="SignUp_form_title border-bottom-1">
                                        <h2>{language === "en" ? 'Sign Up' : 'Regístrate'}</h2>
                                    </div>

                                    <div className={classes.socialDiv}>
                                        <div className={classes.SignUpFormDiv}>
                                            <h2 className="social-div-title">
                                                {language === "en" ? 'Social Media Signup' : 'Registro en redes sociales'}
                                            </h2>
                                        </div>
                                        <div className="icon-button">
                                            <ReactFacebookLoginWithButton
                                                appId="561921711010346"
                                                autoLoad={false}
                                                redirectUri={window.location.protocol + '//' + window.location.host + '/signup'}
                                                fields="name,email,picture"
                                                callback={this.responseFacebook}
                                                isMobile={false}
                                                cssClass="facebook-button"
                                                icon={<FacebookIcon className={classes.leftIcon}
                                                />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.SignUpFormDiv}>
                                    <h2 className="social-div-title">
                                        {language === "en" ? 'Create an Account' : 'Crea una cuenta'}
                                    </h2>
                                </div>

                                <Container maxWidth="sm">
                                    <Formik
                                        enableReinitialize={true}
                                        validationSchema={Yup.object().shape({
                                            // email: Yup.string()
                                            //     .email(language === "en" ? 'Email is invalid' : 'el correo electrónico es invalido')
                                            //     .required(language === "en" ? 'Email is required' : 'correo electronico es requerido'),
                                            // password: Yup.string()
                                            //     .required(language === "en" ? 'Password is required' : 'se requiere contraseña'),
                                            // confirmPassword: Yup.string()
                                            //     .required(language === "en" ? 'Confirm Password is required' : 'Confirme que se requiere contraseña'),
                                            // firstname: Yup.string()
                                            //     .required(language === "en" ? 'First name is required' : 'Se requiere el primer nombre'),
                                            fullname: Yup.string()
                                                .required(language === "en" ? 'Name is required' : 'Se requiere el nombre'),
                                            // lastname: Yup.string()
                                            //     .required(language === "en" ? 'Last name is required' : 'Apellido es obligatorio'),
                                            phone: Yup.string()
                                                .required(language === "en" ? 'Phone number is required' : 'Se requiere número de teléfono'),
                                            // preferred_communication: Yup.string()
                                            //     .required(language === "en" ? 'Preferred communication is required' : 'Se requiere comunicación preferida'),
                                        })}
                                        initialValues={{
                                            email: this.state.email,
                                            fullname: this.state.fullname,
                                            // password: this.state.password,
                                            // confirmPassword: this.state.confirmPassword,
                                            // firstname: this.state.firstname,
                                            // lastname: this.state.lastname,
                                            phone: this.state.phone,
                                            // preferred_communication: this.state.preferred_communication,
                                            subscription_flag: this.state.subscription_flag,
                                            tnc_flag: this.state.tnc_flag,
                                            // date: new Date().getTime(),
                                            ksa: "0",
                                            notes: ""
                                        }}
                                        onSubmit={(values) => {
                                            // if (this.state.tnc_flag) {
                                            values.subscription_flag = this.state.subscription_flag
                                            values.tnc_flag = this.state.tnc_flag
                                            values.source = this.source
                                            values.language = this.state.language
                                            postRequestWithOutToken('/addcandidate', values).then((res) => {
                                                if (res.error) {
                                                    postRequestWithOutToken('/event', { 'event': 'click', value: 'candidate clicked sign up button but got error' });
                                                    this.setState({ openSnackBar: true, signUpAlert: res.error.text });
                                                } else {
                                                    postRequestWithOutToken('/event', { 'event': 'click', value: 'candidate clicked sign up button and it was success' });
                                                    localStorage.setItem('fullname', res.candidateData.fullname);
                                                    localStorage.setItem('firstName', res.candidateData.firstname);
                                                    localStorage.setItem('lastname', res.candidateData.lastname);
                                                    localStorage.setItem('email', res.candidateData.email);
                                                    localStorage.setItem('phone', res.candidateData.phone);
                                                    localStorage.setItem('preferred_communication', res.candidateData.preferred_communication);
                                                    localStorage.setItem('id', res.candidateData.id)
                                                    localStorage.setItem('token', res.candidateData.token)
                                                    localStorage.setItem('language', res.candidateData.language)
                                                    localStorage.setItem('is_match_contact', res.candidateData.is_match_contact)
                                                    localStorage.setItem('subscription_flag', res.candidateData.subscription_flag)
                                                    localStorage.setItem('tnc_flag', res.candidateData.tnc_flag)
                                                    this.props.history.push('/questionnaire', { data: res, lang: this.state.language })
                                                }
                                            }).catch((err) => {
                                                console.log(err);
                                            })
                                        }
                                        }>
                                        {({ errors, values, touched, handleSubmit, handleChange }) => (
                                            <>
                                                <Snackbar className="snackbar" message={signUpAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} onClose={this.handleCloseSnackbar} autoHideDuration={6000} />
                                                <div className={classes.SignUpFormDiv1} >
                                                    <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Email"
                                                            className={classes.textField}
                                                            name="email"
                                                            type="email"
                                                            autoComplete="email"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.email}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                },
                                                            }}
                                                        />
                                                        {/* {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>} */}
                                                    </div>
                                                    {/* <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label={language === "en" ? 'Password' : 'Contraseña'}
                                                            className={classes.textField}
                                                            name="password"
                                                            type="password"
                                                            autoComplete="password"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.password}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                    // error: classes.erroredLabel
                                                                },
                                                            }}
                                                        />
                                                        {errors.password && touched.password && <FormHelperText error>{errors.password}</FormHelperText>}
                                                    </div> */}
                                                    {/* <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label={language === "en" ? 'Confirm Password' : 'Confirmar contraseña'}
                                                            className={classes.textField}
                                                            name="confirmPassword"
                                                            type="password"
                                                            autoComplete="password"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.confirmPassword}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                    // error: classes.erroredLabel
                                                                },
                                                            }}
                                                        />
                                                        {errors.confirmPassword && touched.confirmPassword && <FormHelperText error>{errors.confirmPassword}</FormHelperText>}
                                                    </div> */}
                                                    <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label={language === "en" ? 'Full Name' : 'Nombre completo'}
                                                            className={classes.textField}
                                                            name="fullname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.firstname}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                    // error: classes.erroredLabel
                                                                },
                                                            }}
                                                        />
                                                        {errors.fullname && touched.fullname && <FormHelperText error>{errors.fullname}</FormHelperText>}
                                                    </div>
                                                    {/* <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label={language === "en" ? 'First Name' : 'Primer nombre'}
                                                            className={classes.textField}
                                                            name="firstname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.firstname}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                    // error: classes.erroredLabel
                                                                },
                                                            }}
                                                        />
                                                        {errors.firstname && touched.firstname && <FormHelperText error>{errors.firstname}</FormHelperText>}
                                                    </div>

                                                    <div style={{ marginBottom: 10 }}>
                                                        <TextField
                                                            fullWidth
                                                            label={language === "en" ? 'Last Name' : 'Apellido'}
                                                            className={classes.textField}
                                                            name="lastname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            value={values.lastname}
                                                            onChange={(e) => {
                                                                this.handleChange(e)
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderColor
                                                                }
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.label,
                                                                    focused: classes.focusedLabel,
                                                                },
                                                            }}
                                                        />
                                                        {errors.lastname && touched.lastname && <FormHelperText error>{errors.lastname}</FormHelperText>}
                                                    </div> */}
                                                    <div style={{ marginBottom: 10 }}>
                                                        <MuiPhoneNumber
                                                            fullWidth
                                                            name="phone"
                                                            variant="outlined"
                                                            disableAreaCodes={true}
                                                            defaultCountry={'us'}
                                                            countryCodeEditable={false}
                                                            label={language === "en" ? 'Mobile Phone Number' : 'Número de teléfono móvil'}
                                                            value={values.phone}
                                                            onlyCountries={['us']}
                                                            onChange={(e) => {
                                                                this.handlePhoneChange(e)
                                                            }}
                                                        // autoFormat={false}
                                                        />
                                                        {errors.phone && touched.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
                                                    </div>

                                                    {/* <div style={{ marginBottom: 10 }}>
                                                        <form autoComplete="off">
                                                            <FormControl
                                                                fullWidth
                                                                className={classes.formControl}
                                                            >
                                                                <TextField
                                                                    select
                                                                    open={this.open}
                                                                    onClose={this.handleClose}
                                                                    name="preferred_communication"
                                                                    value={values.preferred_communication}
                                                                    onChange={(e) => {
                                                                        this.handleChange(e)
                                                                    }}
                                                                    variant="outlined"
                                                                    label={language === "en" ? 'Preferred Communication' : 'Comunicación preferida'}
                                                                >
                                                                    <MenuItem value={"Text Message"}> {language === "en" ? "Text Message" : 'Mensaje de texto'} </MenuItem>
                                                                    <MenuItem value={"Email"}> {"Email"} </MenuItem>
                                                                    <MenuItem value={"Phone"}> {language === "en" ? "Phone" : 'Teléfono'} </MenuItem>

                                                                </TextField>
                                                            </FormControl>
                                                            {errors.preferred_communication && touched.preferred_communication && <FormHelperText error>{errors.preferred_communication}</FormHelperText>}
                                                        </form>
                                                    </div> */}

                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Checkbox
                                                            name="subscription_flag"
                                                            color="primary"
                                                            value={values.subscription_flag}
                                                            checked={this.state.subscription_flag}
                                                            onChange={this.handleSubmissionChange}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox',
                                                            }}
                                                        />
                                                        <p onClick={this.handleSubmissionChange}>{language === "en" ? 'I would like to receive emails about the status of my submission.' : 'Me gustaría recibir correos electrónicos sobre el estado de mi envío.'}</p>
                                                    </div>

                                                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Checkbox
                                                        name="tnc_flag"
                                                        color="primary"
                                                        value={values.tnc_flag}
                                                        checked={this.state.tnc_flag}
                                                        onChange={this.handleTNCChange}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                    <p onClick={this.handleTNCChange}>I have read and accepted the <Link to="terms" target="_blank">Terms of Use</Link> and <Link to="privacy-policy" target="_blank">Privacy Policy</Link>.</p>
                                                </div> */}
                                                    <Button
                                                        onClick={handleSubmit}
                                                        className={classes.button}
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        style={{
                                                            marginBottom: "10px"
                                                        }} >
                                                        {language === "en" ? 'Create Account' : 'Crear una cuenta'}
                                                    </Button>
                                                    {/* <Button
                                                        onClick={this.goToLogin}
                                                        variant="text"
                                                        size="medium"
                                                        color="primary" >
                                                        {language === "en" ? 'I already have an account' : 'ya tengo una cuenta'}
                                                    </Button> */}
                                                </div>
                                            </>
                                        )}

                                    </Formik>
                                </Container>

                                {/* end */}
                            </div>
                        </div>
                    </Container>

                </div>
            </div>
        );
    }
}

SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUp);
