import React, { Component } from 'react';
import './../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = ({
    divFlex: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#d3d3d3'
    },
    fontSize: {
        fontSize: '3rem',
        margin: '5px 0px',
    }
});

class PageNotFound extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.divFlex}>
                <h2 className={classes.fontSize}>
                    404
                </h2>
                <h2 className={classes.fontSize}>
                    Page Not Found
                </h2>
            </div>
        );
    }
}
PageNotFound.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageNotFound);