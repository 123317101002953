import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import detectBrowserLanguage from '../../helper/browserLanguage'
import { postRequestWithOutToken } from '../../helper/helper'
import './../../App.css';

import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

class Home extends Component {
  constructor(props) {
    super(props)
  };

  componentWillMount() {
    // const { match: { params } } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    let lang = urlParams.get('lang') || urlParams.get('Lang');
    let renderLang = "en";
    if (!lang) {
      lang = detectBrowserLanguage();
      if (lang.includes("es")) {
        renderLang = "es"
      }
    } else {
      if (lang.includes("es")) {
        renderLang = "es"
      }
    }
    this.setState({ source: source, lang: renderLang })
    const host = window.location.host.split('.');

    if (host && host[0] === 'campaign') {
      this.props.history.push('/')
      // console.log('campaign')
    } else if (host && host[0] === 'portal') {
      this.props.history.push('/login')
      // console.log('portal')
    }

    postRequestWithOutToken(`/get-page/home`).then((res) => {
      if (res.error) {
        this.setState({
          errorMsg: res.error
        });
      } else {
        this.setState({
          content_body: res.page.content_body,
          content_body_es: res.page.content_body_es,
        });
      }
    }).catch((err) => {
      // console.log(err);
    })
  }
  handleLangChange = (e, value) => {
    this.setState({ lang: value })
  }

  signup = () => {
    postRequestWithOutToken('/event', { 'event': 'click', value: 'Signup Click' });
    this.props.history.push(`/signup`, { source: this.state.source, lang: this.state.lang })
  }


  render() {
    const { lang, content_body, content_body_es } = this.state;
    return (
      <div>
        <div className="banner-image">

        </div>
        <div className="language-switcher">
          <ToggleButtonGroup value={lang} exclusive onChange={this.handleLangChange}>
            <ToggleButton value="en">
              English
            </ToggleButton>
            <ToggleButton value="es">
              español
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="title-div">
          <Typography variant="h3" className="title-position" >
            {lang === "es" ? `TRABAJADOR DE AVES DE GRANJA` : `POULTRY FARM WORKER`}
          </Typography>
          <div className="button-div">
            <Button id="checkList1" type="submit" className="click-button" onClick={this.signup} fullWidth={true} variant="outlined" size="large" color="primary">
              {lang === "es" ? `HAGA CLIC AQUÍ PARA APLICAR` : `CLICK HERE TO APPLY`}
            </Button>
          </div>
        </div>

        <div style={{ width: '80%', margin: '0 auto', fontSize: 16, textAlign: 'justify' }}>

          <br />
          {lang === "es" ?
            (
              <>
                {content_body && <div dangerouslySetInnerHTML={{ __html: content_body_es }} ></div>}
              </>
            )
            : (
              <>
                {content_body && <div dangerouslySetInnerHTML={{ __html: content_body }} ></div>}
              </>
            )
          }
          < br />
          <div className="button-div">
            <Button type="submit" className="click-button" onClick={this.signup} fullWidth={true} variant="outlined" size="large" color="primary">
              {lang === "es" ? `HAGA CLIC AQUÍ PARA APLICAR` : `CLICK HERE TO APPLY`}
            </Button>
          </div>

        </div>
        <p className="footer-bottom-line">© 2019 LoneStar Labor Management. All rights reserved. | <Link to="terms" target="_blank">Terms</Link> | <Link to="privacy-policy" target="_blank">Privacy Policy</Link></p>
      </div>
    );
  }
}


export default Home;