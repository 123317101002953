import React, { Component } from 'react';
import './../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// import { Link } from 'react-router-dom'


const styles = ({
    divFlex: {
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '50px'
    },
    fontSize: {
        fontSize: '1.3rem',
        margin: '10px 0px',
        fontWeight: '600'
    },
    fontSize2: {
        fontSize: '1rem',
        margin: '10px 0px',
        fontWeight: '600'
    },
    link: {
        color: 'inherits',
        textDecoration: 'none',
    },
    footer: {
        height: '80px',
        background: '#164d5e',
        width: '100%',
        color: 'white',
        paddingTop: '20px',
        textAlign: 'center',
        position: 'absolute',
        bottom: '0',
        fontSize: '16px'
    }
});

class SuccessResponse extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstname: '',
        };
        this.paramLanguage = this.props.location && this.props.location.state && this.props.location.state.lang;
    };

    async componentWillMount() {

        const firstName = await localStorage.getItem('firstName');
        const lastname = await localStorage.getItem('lastname');

        this.setState({
            firstName: firstName,
            lastname: lastname,
        }, () => {
            this.setState({ dataSet: true })
        })
    }

    logOut = () => {
        sessionStorage.clear();
        localStorage.clear();
    }

    goToProfile = () => {
        this.props.history.push('/profile', { lang: this.paramLanguage })
    }

    render() {
        const { classes } = this.props;
        const { firstName, lastname, positionName } = this.state;

        return (
            <div>
                <div className={classes.divFlex}>
                    <div style={{ marginBottom: 50 }}>
                        <img src="/image/lonestar-logo-black.png" alt="img" style={{ height: 150 }} />
                    </div>
                    <p className={classes.fontSize} style={{ color: '#164d5e', padding: '15px' }}>
                        Candidate Questionnaire Submitted
                    </p>
                    <div style={{ margin: 50 }}>
                        <img src="/image/success.svg" alt="img" style={{ height: 100 }} />
                    </div>
                    <p className={classes.fontSize2} style={{ padding: '15px', textAlign: 'center' }}>
                        Thank you for completing the candidate questionnaire. Your submission has been successfully received.
                    </p>
                    {/* <p className={classes.fontSize}>
                        Update you profile at anytime by visitng
                        <span onClick={this.goToProfile} style={{ marginLeft: 8, cursor: 'pointer', textDecoration: 'underline' }}>
                            Profile
                        </span>
                    </p> */}
                </div>
                {/* <div className={classes.footer}>
                    <p>
                        Logged in as {firstName} {lastname} <span> <Link to="/login" className="fontWhite" onClick={this.logOut}> Logout </Link> </span>
                    </p>
                </div> */}
            </div>
        );
    }
}

SuccessResponse.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SuccessResponse);