import React from 'react';
import './App.css';
// import CandidateLogin from './component/candidateLogin/login';
import SignUp from './component/signUp/SignUp';
import Home from './component/home/home';
import Profile from './component/completeProfile/profile';
import PageNotFound from './component/PageNotFound/PageNotFound';
import { createMuiTheme } from '@material-ui/core/styles';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles';
import PrivacyPolicy from './component/privacyPolicy/privacyPolicy';
import Terms from './component/terms/terms';
import Questionnaire from './component/questionnaire/questionnaire';
import SuccessResponse from './component/questionnaire/successResponse';
import 'url-search-params-polyfill';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#164d5e',
    },
    secondary: {
      main: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path={'/terms'} component={Terms} />
            <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
            <Route exact path={'/'} component={Home} />
            {/* <Route exact path={'/candidate-login'} component={CandidateLogin} /> */}
            <Route exact path={'/signup'} component={SignUp} />
            <Route exact path={'/profile'} component={Profile} />
            <Route exact path={'/questionnaire'} component={Questionnaire} />
            <Route exact path={'/question-success'} component={SuccessResponse} />
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
