import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from '@material-ui/core/Checkbox';
import "./profile.css";
import { postRequestWithOutToken } from '../../helper/helper';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import detectBrowserLanguage from '../../helper/browserLanguage'

const styles = ({
    Logo: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0 10px 0',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        boxShadow: '0 3px 5px #aaa',
    },
    headerH2:
    {
        fontSize: '1.7em',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    },
    italic:
    {
        color: '#cacaca',
        fontStyle: 'italic',
        fontWeight: '300',
        fontSize: '1.7em',
        fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
    },
    headerLogo:
    {
        display: 'flex',
        alignItems: 'center',
    },
    signUp: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '50px 0',
    },
    leftIcon: {
        marginRight: '10px',
        width: '35px',
        height: '35px',
        color: 'white',
    },
    socialDiv:
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px',
        marginBottom: '10px',
        borderBottom: '1px solid #eee',
    },
    SignUpFormDiv:
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    SignUpFormDiv1:
    {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '10%'
    },
});


class Profile extends Component {
    constructor(props) {
        super(props)
        this.login = this.login.bind(this);
        this.state = {
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            communicationPreferredData: '',
            subscription_flag: false,
            tnc_flag: false,
            is_match_contact: false,
            lang: ''
        };
        this.paramLanguage = this.props.location && this.props.location.state && this.props.location.state.lang;

    };

    async login() {
        const token = await localStorage.getItem('token')
        const id = await localStorage.getItem('id')
        const tokenData = {
            token: token,
            candidate_id: id,
        }
        const data = { ...this.state, ...tokenData }
        postRequestWithOutToken('/candidateupdate', data).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err);
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmissionChange = () => {
        this.setState({
            subscription_flag: !this.state.subscription_flag
        })
    }

    handleTNCChange = () => {
        console.log(this.state.tnc_flag)
        this.setState({
            tnc_flag: !this.state.tnc_flag
        })
    }

    handleMatchChange = () => {
        this.setState({
            is_match_contact: !this.state.is_match_contact,
        })
    }

    handlePhoneChange = (value) => {
        this.setState({
            phone: value
        });
    }

    handleCommunicationChanged = (event) => {
        this.setState({
            communicationPreferredData: event.target.value
        })
    }

    componentWillMount() {
        const email = localStorage.getItem('email');
        const firstname = localStorage.getItem('firstName');
        const lastname = localStorage.getItem('lastname');
        const phone = localStorage.getItem('phone');
        const communicationPreferredData = localStorage.getItem('preferred_communication');
        const isMatchContact = (localStorage.getItem('is_match_contact') == 1);
        const subscriptionFlag = (localStorage.getItem('subscription_flag') == 1);
        // const tncFlag = (localStorage.getItem('tnc_flag') === 1);

        this.setState({
            email: email,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            communicationPreferredData: communicationPreferredData,
            is_match_contact: isMatchContact,
            subscription_flag: subscriptionFlag,
            // tnc_flag: tncFlag
        }, () => {
            this.setState({ dataSet: true })
        })

        const urlParams = new URLSearchParams(window.location.search);
        let lang = urlParams.get('lang') || urlParams.get('Lang');
        let renderLang = "en"

        if (!lang) {
            if (this.paramLanguage) {
                if (this.paramLanguage.includes("es")) {
                    renderLang = "es"
                }
            } else {
                lang = detectBrowserLanguage();
                if (lang.includes("es")) {
                    renderLang = "es"
                }
            }
        } else {
            if (lang.includes("es")) {
                renderLang = "es"
            }
        }
        this.setState({
            lang: renderLang
        })
    }

    logOut = () => {
        sessionStorage.clear();
        localStorage.clear();
    }

    render() {
        const { classes } = this.props;
        const { firstname, lastname, phone, email, is_match_contact, lang, communicationPreferredData, tnc_flag } = this.state;

        return (

            <div className="signUpContainer">
                <div className="SignUpForm">
                    <Container maxWidth="md">
                        <div className="SignUpForm-new-width dFlex-profile">
                            <div className="SignUpForm-title-div left_side-profile">
                                <img src="/image/lonestar-white.png" alt="img" style={{ width: '55%' }} />
                            </div>

                            <div style={{ paddingTop: '25px' }}>
                                <div className="FormTitle">
                                    <h2>{lang === "en" ? 'Update Your Profile' : 'Actualiza tu perfil'}</h2>
                                </div>
                                <Formik
                                    enableReinitialize={true}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email(lang === "en" ? 'Email is invalid' : 'el correo electrónico es invalido')
                                            .required(lang === "en" ? 'Email is required' : 'correo electronico es requerido'),
                                        firstname: Yup.string()
                                            .required(lang === "en" ? 'First name is required' : 'Se requiere el primer nombre'),
                                        lastname: Yup.string()
                                            .required(lang === "en" ? 'Last name is required' : 'Apellido es obligatorio'),
                                        phone: Yup.string()
                                            .required(lang === "en" ? 'Phone number is required' : 'Se requiere número de teléfono'),
                                        communicationPreferredData: Yup.string()
                                            .required(lang === "en" ? 'Preferred communication is required' : 'Se requiere comunicación preferida'),
                                        tnc_flag: Yup.bool()
                                            .test(
                                                'consent',
                                                'You have to agree with our Terms and Conditions',
                                                value => value === true
                                            )
                                            .required(
                                                'You have to agree with our Terms and Conditions'
                                            ),
                                    })}
                                    initialValues={{
                                        email: email,
                                        firstname: firstname,
                                        lastname: lastname,
                                        phone: phone,
                                        communicationPreferredData: communicationPreferredData,
                                        tnc_flag: tnc_flag
                                    }}
                                    onSubmit={() => {
                                        this.login()
                                    }
                                    }>
                                    {({ errors, touched, values, handleSubmit }) => (
                                        <>
                                            {console.log('errors', errors)}

                                            <div className={classes.SignUpFormDiv1}>
                                                <div style={{ marginBottom: 10 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email*"
                                                        value={values.email}
                                                        className={classes.textField}
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.placeholderColor
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.label,
                                                                focused: classes.focusedLabel,
                                                            },
                                                        }}
                                                    />
                                                    {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                                                </div>
                                                <div style={{ marginBottom: 10 }}>
                                                    <TextField
                                                        fullWidth
                                                        value={values.firstname}
                                                        label={lang === "en" ? 'First Name*' : 'Primer nombre*'}
                                                        className={classes.textField}
                                                        name="firstname"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.placeholderColor
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.label,
                                                                focused: classes.focusedLabel,
                                                            },
                                                        }}
                                                    />
                                                    {errors.firstname && touched.firstname && <FormHelperText error>{errors.firstname}</FormHelperText>}
                                                </div>
                                                <div style={{ marginBottom: 10 }}>
                                                    <TextField
                                                        fullWidth
                                                        label={lang === "en" ? 'Last Name*' : 'Apellido*'}
                                                        value={values.lastname}
                                                        className={classes.textField}
                                                        name="lastname"
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.placeholderColor
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.label,
                                                                focused: classes.focusedLabel,
                                                            },
                                                        }}
                                                    />
                                                    {errors.lastname && touched.lastname && <FormHelperText error>{errors.lastname}</FormHelperText>}
                                                </div>
                                                <div style={{ marginBottom: 10 }}>
                                                    <MuiPhoneNumber
                                                        onChange={(e) => {
                                                            this.handlePhoneChange(e)
                                                        }}
                                                        fullWidth
                                                        disableAreaCodes={true}
                                                        defaultCountry={'us'}
                                                        variant="outlined"
                                                        countryCodeEditable={false}
                                                        value={values.phone}
                                                        label={lang === "en" ? 'Phone Number*' : 'Número de teléfono*'}
                                                        name="phone Number"
                                                        onlyCountries={['us']}
                                                    />
                                                    {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                                                </div>

                                                <div style={{ marginBottom: 10 }}>
                                                    <form autoComplete="off">
                                                        <FormControl
                                                            fullWidth
                                                            className={classes.formControl}
                                                        >
                                                            <TextField
                                                                select
                                                                open={this.open}
                                                                onClose={this.handleClose}
                                                                onOpen={this.handleOpen}
                                                                name="communicationPreferredData"
                                                                value={values.communicationPreferredData}
                                                                onChange={(e) => {
                                                                    this.handleChange(e)
                                                                }}
                                                                variant="outlined"
                                                                label={lang === "en" ? 'Communication Preferred' : 'Comunicación preferida'}
                                                            >

                                                                <MenuItem value={"Text Message"}> {lang === "en" ? "Text Message" : 'Mensaje de texto'} </MenuItem>
                                                                <MenuItem value={"Email"}> {"Email"} </MenuItem>
                                                                <MenuItem value={"Phone"}> {lang === "en" ? "Phone" : 'Teléfono'} </MenuItem>

                                                            </TextField>
                                                        </FormControl>
                                                    </form>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Checkbox
                                                        checked={is_match_contact}
                                                        onChange={this.handleMatchChange}
                                                        value="false"
                                                        color="primary"
                                                        name="choiceCheckBox"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                    <p onClick={this.handleMatchChange} className="checkBoxFontSize">{lang === "en" ? "If I'm a match contact me via text." : "Si soy un partido contáctame por mensaje de texto."}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Checkbox
                                                        checked={this.state.subscription_flag}
                                                        onChange={this.handleSubmissionChange}
                                                        value="false"
                                                        color="primary"
                                                        name="emailCheckBox"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                    <p onClick={this.handleSubmissionChange} className="checkBoxFontSize">{lang === "en" ? "I would like to receive emails about the status of my submission." : "Me gustaría recibir correos electrónicos sobre el estado de mi envío"} </p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                                    <Checkbox

                                                        checked={this.state.tnc_flag}
                                                        onChange={(e) => {
                                                            this.handleTNCChange(e)
                                                        }}
                                                        value={values.tnc_flag}
                                                        color="primary"
                                                        name="termsCheckbox"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />

                                                    <p onClick={this.handleTNCChange} className="checkBoxFontSize" style={{ marginBottom: 0 }}>{lang === "en" ? 'I have read and accepted the' : 'He leído y acepto el'} <Link to="terms" target="_blank"> {lang === "en" ? 'Terms of Use' : 'Términos de Uso'} </Link> {lang === "en" ? ' and ' : ' y '}<Link to="privacy-policy" target="_blank">{lang === "en" ? ' Privacy Policy ' : ' Política de privacidad '}</Link>.</p>
                                                </div>
                                                <span className="d-block">{errors.tnc_flag && touched.tnc_flag && <FormHelperText error>{errors.tnc_flag}</FormHelperText>}</span>
                                                <Button
                                                    onClick={handleSubmit}
                                                    className={classes.button}
                                                    variant="contained"
                                                    size="medium"
                                                    color="primary"
                                                    style={{ marginTop: 15 }} >
                                                    {lang === "en" ? 'Save Changes' : 'Guardar cambios'}
                                                </Button>

                                            </div>
                                        </>
                                    )}
                                </Formik>

                            </div>

                        </div>
                    </Container>
                </div>

                <div className="footer" style={{ marginTop: 70 }}>
                    <p>
                        Logged in as <span> {firstname} {lastname}</span> <span> <Link to="/login" className="fontWhite" onClick={this.logOut} style={{
                            textDecoration: 'underline'
                        }}> Logout </Link> </span>
                    </p>
                </div>
            </div>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
