import React, { Component } from 'react';
import './../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { postRequestWithOutToken } from '../../helper/helper';

const styles = ({
  divFlex: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#d3d3d3',
    overflowY: 'auto',
  },
  fontSize: {
    fontSize: '3rem',
    margin: '5px 0px',
  }
});

class Terms extends Component {
  constructor(props) {
    super(props)
    this.state = { content_body: '' }
  }
  componentWillMount() {

    postRequestWithOutToken(`/get-page/terms`).then((res) => {
      if (res.error) {
        this.setState({
          errorMsg: res.error
        });
      } else {
        this.setState({
          content_body: res.page.content_body,
          page_title: res.page.page_title,
        });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    const { content_body } = this.state;
    console.log(content_body)
    const { classes } = this.props;
    return (
      <div className={classes.divFlex}>
        {content_body && <div dangerouslySetInnerHTML={{ __html: content_body }} ></div>}
        {/* <h2 className={classes.fontSize}>
                    Coming Soon
                </h2> */}
      </div>
    );
  }
}
Terms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Terms);